import React, { useEffect } from "react"
import { Link as GatsbyLink } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { useTranslation, Link } from "@3nvi/gatsby-theme-intl"
import YouTube from "../components/YouTube"
// import Header from "../components/header"
import { usePageContext } from "@3nvi/gatsby-theme-intl"
import Carousel from "../components/Slider"
import Layout from "../components/layout"
import ViacomCBS from "../images/viacomcbs.svg"
import AdCouncil from "../images/adcouncil.svg"
import Arrow from "../images/arrow.svg"
import Circles from "../images/circles.svg"
// import Image from "../components/image"
import SEO from "../components/seo"
import partnerLogos from "../partners"
import useScript from "../useScript"
import { slugify } from "../helpers"
import AlertBar from "../components/alert-bar"

// Hook

const rows = partnerLogos
  .reduce((r, el, i) => {
    i % 8 === 0 && r.push([])
    r[r.length - 1].push(el)
    return r
  }, [])
  .map((element, index) => {
    return (
      <div className="slide" key={index}>
        <div className="slide__inner">
          {element.map(({ Company, Filename, wide }, index) => {
            return (
              <div
                className={"logo" + (wide ? " wide-" + wide : "")}
                key={Company}
              >
                {Filename && (
                  <img alt={Company} src={"/logos/" + Filename}></img>
                )}
              </div>
            )
          })}
        </div>
      </div>
    )
  })

const IndexPage = props => {
  const { supportedLanguages, originalPath } = usePageContext()
  const { t } = useTranslation()
  // const [loaded, error] = useScript(
  //   "https://app.viacomcbs-adcouncil.civicengine.com/embed.js"
  // )
  // if (loaded && window !== undefined && window.civicengine !== undefined) {
  //   window.civicengine.init()
  // }
  // useEffect(() => {
  //   if (loaded) {
  //     window.civicengine.init()
  //   }
  // }, [t])
  return (
    <Layout location={props.location}>
      <SEO title={t("home.heading")} />
      <header className="black">
        <div className="container">
          <ul className="nav__langs">
            {supportedLanguages.map(supportedLang => {
              let langlink = supportedLang
              if (supportedLang === "en") {
                langlink = ""
              }
              return (
                <li>
                  <GatsbyLink
                    aria-label={`Change language to ${t(
                      "language." + supportedLang
                    )}`}
                    key={supportedLang}
                    to={`/${langlink}${originalPath}`}
                    activeClassName="active"
                  >
                    {t(`language.${supportedLang}`)}
                  </GatsbyLink>
                </li>
              )
            })}
          </ul>
        </div>
      </header>
      <div className="hero black">
        <Arrow className="arrow-svg"></Arrow>
        <div className="container" id="vote">
          <h1 dangerouslySetInnerHTML={{ __html: t("home.hero.heading") }} />

          <div className="hero-sticker yellow">
            <div dangerouslySetInnerHTML={{ __html: t("home.hero.text") }} />

            <div className="pledge-container en">
              {/* <div
                className="civicengine-address"
                data-default-locale={t("common.lang")}
              /> */}
              <Circles className="circles-svg"></Circles>
            </div>
            <div style={{ textAlign: "center" }}>
              <OutboundLink
                className="privacy-policy-link"
                href="https://www.civicengine.com/privacy-policy
"
                target={"_blank"}
              >
                {t(`home.privacy-policy`)}
              </OutboundLink>
            </div>
          </div>
        </div>
      </div>
      <section>
        <article className="row black">
          <div className="container" id="steps">
            <h2 className="steps-heading">{t("home.steps.heading")}</h2>
          </div>
          <Step
            heading={t("home.registration.heading")}
            text={t("home.registration.text")}
          ></Step>
          <Step
            heading={t("home.plan.heading")}
            text={t("home.plan.text")}
          ></Step>
          <Step
            heading={t("home.research.heading")}
            text={t("home.research.text")}
          ></Step>
          <Step
            heading={t("home.count.heading")}
            text={t("home.count.text")}
          ></Step>
        </article>

        {/* <article className="row black">
          <div className="container step">
            <h3>{t("home.count.heading")}</h3>
            <div
              dangerouslySetInnerHTML={{ __html: t("home.count.text") }}
            ></div>
          </div>
        </article> */}
      </section>
      <section className="row yellow">
        <div className="container about" id="about-the-campaign">
          <h2>{t("home.about.heading")}</h2>
          <div
            className="container-small"
            dangerouslySetInnerHTML={{ __html: t("home.about.text") }}
          ></div>
        </div>
        <div className="video-parent">
          <YouTube video="cRu4zKJ759Q" autoplay="0" rel="0" modest="1" />
        </div>
      </section>
      <div className="row black" id="partners">
        <div class="container partners">
          <h2>{t("home.partners.heading")}</h2>
        </div>
        <Carousel>{rows}</Carousel>
      </div>
      <div className="row white">
        <div class="container powered-by">
          <h2>{t("home.powered-by.heading")}</h2>
          <div className="powered-by-logos">
            <ViacomCBS className="viacomcbs" />
            <AdCouncil className="adcouncil" />
          </div>
        </div>
      </div>

      {/* <Link to="/page-2/">{t("common.goToSecondPage")}</Link> */}
      <AlertBar>
        <div dangerouslySetInnerHTML={{ __html: t(`home.alert-bar`) }} />
      </AlertBar>
    </Layout>
  )
}

const Step = ({ heading, text }) => {
  return (
    <div className="step">
      <div className="container">
        <h3>{heading}</h3>
        <div dangerouslySetInnerHTML={{ __html: text }}></div>
      </div>
    </div>
  )
}

export default IndexPage
