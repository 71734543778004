import React from "react"

const YouTube = ({ video, autoplay }) => {
  var videoSrc =
    "https://www.youtube-nocookie.com/embed/" +
    video +
    "?autoplay=" +
    autoplay +
    "&rel=0&modestbranding=1"

  return (
    <div className="video-container">
      <iframe
        className="player"
        type="text/html"
        width="100%"
        height="100%"
        src={videoSrc}
        frameBorder="0"
      />
    </div>
  )
}

export default YouTube
